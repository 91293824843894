export function isGpsCoordinateValid (coordinate) {
  let reg = new RegExp('^(\\-)?([0-9]+(\\.[0-9]+)?)$')
  return reg.test(coordinate)
}

export function isHeightValid (altitude) {
  let reg = new RegExp('^(-?[0-9]+(\\.[0-9]+)?)$')
  return reg.test(altitude)
}

export function isPanValid (pan) {
  pan = Math.floor(Number(pan))

  return isNaN(pan) === false && pan >= -180 && pan <= 180
}

export function isNorthAngleValid (angle) {
  angle = Math.floor(Number(angle))

  return isNaN(angle) === false && angle >= 0 && angle <= 360
}

export function isTiltValid (tilt) {
  tilt = Math.floor(Number(tilt))

  return isNaN(tilt) === false && tilt >= -90 && tilt <= 90
}

export function isZoomValid (zoom) {
  zoom = Math.floor(Number(zoom))

  return isNaN(zoom) === false && zoom >= 5
}