<template>
  <g>
		<polygon :points="points" :stroke="strokeColor" stroke-opacity="0.1" stroke-width="6"></polygon>
    <polygon :points="points" :fill="fillColor" stroke-linejoin="square" stroke-width="4"></polygon>
  </g>
</template>

<script>
function valueToPoint(value, index, total) {
  var x = 0;
  var y = -12 * 0.8;
  var angle = ((Math.PI * 2) / total) * index;
  var cos = Math.cos(angle);
  var sin = Math.sin(angle);
  var tx = x * cos - y * sin + value;
  var ty = x * sin + y * cos + value;
  return {
    x: tx,
    y: ty
  };
}

export default {
	name: "polygraph",
	props: [
    "total",
		"fillColor",
		"strokeColor"
  ],
  data() {
    return {
      stats: [
        { 
          label: 'A',
          value: 14 
        },
        {
          label: 'B',
          value: 14 
        },
        { 
          label: 'C',
          value: 14 
        },
        { 
          label: 'D',
          value: 14 
        },
        { 
          label: 'E',
          value: 14 
        },
        { 
          label: 'F',
          value: 14 
        },
        { 
          label: 'G',
          value: 14 
        },
        { 
          label: 'H',
          value: 14 
        },
      ]
    }
  },
	computed: {
    points: function() {
      var numberOfPoints = this.total
      return this.stats
        .map(function(stat, i) {
          var point = valueToPoint(stat.value, i, numberOfPoints);
          return point.x + "," + point.y;
        })
        .join(" ");
		}
  }
}
</script>
