<template>
  <div style="marginTop: 8px;">
    <a-row>
      <a-col :span="4" :offset="0">
        <a-button @click="cancel()">
          <a-icon type="left" /> {{ $t("common.goBack") }}
        </a-button>
      </a-col>
    </a-row>
      <a-col
        style="marginRight: 20px"
        :md="{ span: 20, offset: 0 }"
        :lg="{ span: 20, offset: 0 }"
        :xl="{ span: 20, offset: 0 }"
        :xxl="{ span: 20, offset: 0 }"
      >
      <a-card :title="$t('points.properties')">
        <a-form>
          <a-row>
            <a-col :span="8" style="marginTop: 8px">
              <p>{{ $t('cameraSettings.recordModeLabel') }}</p>
            </a-col>
            <a-col :span="8">
              <a-select
                :options="availRecordModes"
                :defaultValue="lastValues.recordMode"
                :value="this.form[0].recordMode !== null ? this.form[0].recordMode : lastValues.recordMode"
                @change="(val) => handleRecordModeChange(val)"
              >
              </a-select>
            </a-col>
          </a-row>
          <a-row v-if="isPhotoModeSelected">
            <a-col :span="4" style="marginTop: 8px">
              <p>{{ $t('cameraSettings.photoIntervalLabel') }}</p>
            </a-col>
            <a-col :span="3">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="photoIntervalMinValue"
                  :step="0.1"
                  :defaultValue="lastValues.photoModeInterval"
                  :value="this.form[0].photoModeInterval !== null ? this.form[0].photoModeInterval : 5"
                  @change="(val) => handlePropChange('photoModeInterval', val)"
              />
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ photoIntervalUnitLabel }}
              </span>
            </a-col>
          </a-row>
          <a-row v-if="isThermalModeSelected">
            <a-col :span="8" style="marginTop: 8px">
              <p>{{ $t('cameraSettings.thermalModeLabel') }}</p>
            </a-col>
            <a-col :span="8">
              <a-select
                  :options="availThermalModes"
                  :defaultValue="lastValues.thermalMode"
                  :value="this.form[0].thermalMode !== null ? this.form[0].thermalMode : lastValues.thermalMode"
                  @change="(val) => handleThermalModeChange(val)"
              />
            </a-col>
          </a-row>
          <a-row v-if="isThermalModeSelected">
            <a-col :span="8" style="marginTop: 8px">
              <p>{{ $t('cameraSettings.thermalRangeHighLabel') }}</p>
            </a-col>
            <a-col :span="8">
              <a-checkbox
                :checked="this.form[0].thermalRange === 'high'"
                @change="(e) => handleThermalRangeChange(e.target.checked ? 'high' : 'low')"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['panAngle']"
                @change="(e) => handleCheckboxChange('panAngle', e.target.checked, updatePanMarker)"
              >
                {{ $t("segments.pan") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.pan") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="0"
                  :max="360"
                  :disabled="!propsChecked['panAngle']"
                  :defaultValue="lastValues.panAngle"
                  :marks="panSliderMarks"
                  :value="this.form[0].panAngle !== null ? this.form[0].panAngle : 0"
                  @change="(val) => handlePropChange('panAngle', val, updatePanMarker)"
              />
            </a-col>
            <a-col :span="8" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('pan')"
              >
                <a-slider
                  :included="false"
                  :min="0"
                  :max="360"
                  :disabled="!propsChecked['panAngle']"
                  :defaultValue="lastValues.panAngle"
                  :marks="panSliderMarks"
                  :value="this.form[0].panAngle !== null ? this.form[0].panAngle : 0"
                  @change="(val) => handlePropChange('panAngle', val, updatePanMarker)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.panAngle ? segment.panAngle : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("points.viewpoints.labelDegreePan") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['tiltAngle']"
                @change="(e) => handleCheckboxChange('tiltAngle', e.target.checked)"
              >
                {{ $t("segments.tilt") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.tilt") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="-90"
                  :max="90"
                  :disabled="!propsChecked['tiltAngle']"
                  :defaultValue="lastValues.tiltAngle"
                  :marks="tiltSliderMarks"
                  :value="this.form[0].tiltAngle !== null ? this.form[0].tiltAngle : -30"
                  @change="(val) => handlePropChange('tiltAngle', val)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('tiltAngle')"
              >
                <a-slider
                  :included="false"
                  :min="-90"
                  :max="90"
                  :disabled="!propsChecked['tiltAngle']"
                  :defaultValue="lastValues.tiltAngle"
                  :marks="tiltSliderMarks"
                  :value="this.form[0].tiltAngle !== null ? this.form[0].tiltAngle : -30"
                  @change="(val) => handlePropChange('tiltAngle', val)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.tilt ? segment.tilt : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("points.viewpoints.labelDegreeTilt") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['zoom']"
                @change="(e) => handleCheckboxChange('zoom', e.target.checked)"
              >
                {{ $t("segments.zoom") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.zoom") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="0"
                  :max="100"
                  :step="1"
                  :disabled="!propsChecked['zoom']"
                  :defaultValue="lastValues.zoom"
                  :marks="zoomSliderMarks"
                  :value="this.form[0].zoom !== null ? this.form[0].zoom : 0"
                  @change="(val) => handlePropChange('zoom', val)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('zoom')"
              >
                <a-slider
                  :included="false"
                  :min="0"
                  :max="100"
                  :step="1"
                  :disabled="!propsChecked['zoom']"
                  :defaultValue="lastValues.zoom"
                  :marks="zoomSliderMarks"
                  :value="this.form[0].zoom !== null ? this.form[0].zoom : 0"
                  @change="(val) => handlePropChange('zoom', val)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.zoom ? segment.zoom : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("segments.properties.labelPercent") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['horizontalSpeed']"
                @change="(e) => handleCheckboxChange('horizontalSpeed', e.target.checked)"
              >
                {{ $t("segments.horizontalSpeed") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.horizontalSpeed") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="1"
                  :max="12"
                  :step="0.1"
                  :disabled="!propsChecked['horizontalSpeed']"
                  :defaultValue="lastValues.horizontalSpeed"
                  :marks="horizontalSpeedSliderMarks"
                  :value="this.form[0].horizontalSpeed !== null ? this.form[0].horizontalSpeed : 8"
                  @change="(val) => handlePropChange('horizontalSpeed', val)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('horizontalSpeed')"
              >
                <a-slider
                  :included="false"
                  :min="1"
                  :max="12"
                  :step="0.1"
                  :disabled="!propsChecked['horizontalSpeed']"
                  :defaultValue="lastValues.horizontalSpeed"
                  :marks="horizontalSpeedSliderMarks"
                  :value="this.form[0].horizontalSpeed !== null ? this.form[0].horizontalSpeed : 8"
                  @change="(val) => handlePropChange('horizontalSpeed', val)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.horizontalSpeed ? segment.horizontalSpeed : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("segments.properties.labelSpeed") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['verticalSpeed']"
                @change="(e) => handleCheckboxChange('verticalSpeed', e.target.checked)"
              >
                {{ $t("segments.verticalSpeed") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.verticalSpeed") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="0.5"
                  :max="2.0"
                  :step="0.1"
                  :disabled="!propsChecked['verticalSpeed']"
                  :defaultValue="lastValues.verticalSpeed"
                  :marks="verticalSpeedSliderMarks"
                  :value="this.form[0].verticalSpeed !== null ? this.form[0].verticalSpeed : 2.0"
                  @change="(val) => handlePropChange('verticalSpeed', val)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('verticalSpeed')"
              >
                <a-slider
                  :included="false"
                  :min="0.5"
                  :max="2.0"
                  :step="0.1"
                  :disabled="!propsChecked['verticalSpeed']"
                  :defaultValue="lastValues.verticalSpeed"
                  :marks="verticalSpeedSliderMarks"
                  :value="this.form[0].verticalSpeed !== null ? this.form[0].verticalSpeed : 2.0"
                  @change="(val) => handlePropChange('verticalSpeed', val)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.verticalSpeed ? segment.verticalSpeed : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("segments.properties.labelSpeed") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['returnSpeed']"
                @change="(e) => handleCheckboxChange('returnSpeed', e.target.checked)"
              >
                {{ $t("segments.returnSpeed") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.returnSpeed") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="1"
                  :max="12"
                  :step="0.1"
                  :disabled="!propsChecked['returnSpeed']"
                  :defaultValue="lastValues.returnSpeed"
                  :marks="returnSpeedSliderMarks"
                  :value="this.form[0].returnSpeed !== null ? this.form[0].returnSpeed : 8"
                  @change="(val) => handlePropChange('returnSpeed', val)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('returnSpeed')"
              >
                <a-slider
                  :included="false"
                  :min="1"
                  :max="12"
                  :step="0.1"
                  :disabled="!propsChecked['returnSpeed']"
                  :defaultValue="lastValues.returnSpeed"
                  :marks="returnSpeedSliderMarks"
                  :value="this.form[0].returnSpeed !== null ? this.form[0].returnSpeed : 8"
                  @change="(val) => handlePropChange('returnSpeed', val)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.returnSpeed ? segment.returnSpeed : $t("form.noValue") }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("segments.properties.labelSpeed") }}
              </span>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" style="marginTop: 8px">
              <a-checkbox
                v-if="isFPManager"
                :checked="propsChecked['corridorSize']"
                @change="(e) => handleCheckboxChange('corridorSize', e.target.checked, updateCorridor)"
              >
                {{ $t("segments.corridor") }}
              </a-checkbox>
              <div v-else>{{ $t("segments.corridor") }}</div>
            </a-col>
            <a-col :span="2">
              <a-input-number id="inputNumber"
                  :included="false"
                  :min="5"
                  :max="30"
                  :disabled="!propsChecked['corridorSize']"
                  :defaultValue="10"
                  :marks="corridorSliderMarks"
                  :value="this.form[0].corridorSize !== null ? this.form[0].corridorSize : 10"
                  @change="(val) => handlePropChange('corridorSize', val, updateCorridor)"
              />
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-item
                v-if="isFPManager"
                :validate-status="getValidateStatus('corridorSize')"
              >
                <a-slider
                  :included="false"
                  :min="5"
                  :max="30"
                  :disabled="!propsChecked['corridorSize']"
                  :defaultValue="10"
                  :marks="corridorSliderMarks"
                  :value="this.form[0].corridorSize !== null ? this.form[0].corridorSize : 10"
                  @change="(val) => handlePropChange('corridorSize', val, updateCorridor)"
                />
              </a-form-item>
              <div v-else style="marginTop: 8px;">
                {{ segment.corridorSize }}
              </div>
            </a-col>
            <a-col :span="5" :offset="1" style="marginTop: 8px;">
              <span class="info-content">
                {{ $t("segments.properties.labelCorridor") }}
              </span>
            </a-col>
          </a-row>

          <a-form-item v-if="isFPManager">
            <a-row>
              <a-col
                :xs="{ span: 10, offset: 2 }"
                :sm="{ span: 11, offset: 0 }"
                :md="{ span: 9, offset: 2 }"
                :xl="{ span: 5, offset: 8 }"
                :xxl="{ span: 4, offset: 8 }"
              >
                <a-button
                  @click="() => cancel()"
                  class="btn btn-danger"
                  type="danger"
                >
                  {{ $t("form.cancel") }}
                </a-button>
              </a-col>
              <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
                <a-button
                  @click="() => saveProperties()"
                  class="btn"
                  type="primary"
                >
                  {{ $t("form.save") }}
                </a-button>
              </a-col>
            </a-row>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Card,
  Row,
  Input,
  Button,
  Form,
  Checkbox,
  Slider,
  InputNumber,
  Select
} from "ant-design-vue";
import { isNorthAngleValid, isTiltValid } from "../FormValidation";
import { isFPManager } from "../../../utils/userAuth";

Vue.use(Card);
Vue.use(Row);
Vue.use(Input);
Vue.use(Button);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(InputNumber);
Vue.use(Select);

const VALID_RECORD_MODES = ['videohd', 'video4k', 'gpslapse', 'photolapse', 'thermal', 'disabled']
const VALID_THERMAL_MODES = ['rainbow', 'fusion', 'black_hot', 'white_hot']

export default {
  name: "segment-props-form",
  props: {
    segment: {
      type: [Object, Array],
    },
    eventBus: {
      type: Object,
    },
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    isPhotoModeSelected() {
      return ['gpslapse', 'photolapse'].includes(this.form[0].recordMode)
    },
    isThermalModeSelected() {
      return this.form[0].recordMode === "thermal"
    },
    photoIntervalUnitLabel() {
      if (this.isPhotoModeSelected) {
        let photoMode = this.form[0].recordMode

        if (photoMode == 'gpslapse') {
          return this.$tc('units.meter', 2)
        }
        return this.$tc('units.second', 2)
      }
      return "Invalid label"
    },
    photoIntervalMinValue() {
      if (this.isPhotoModeSelected) {
        let photoMode = this.form[0].recordMode
        if (photoMode == 'gpslapse') {
          return 1.0
        }
      }
      return 2.0
    }
  },
  data() {
    return {
      form: [],
      panSliderMarks: {
        0: '0°',
        180: '180°',
        360: '360°'
      },
      tiltSliderMarks: {
        '-90': '-90°',
        '-30': '-30°',
        '0': '0°',
        '90': '90°'
      },
      zoomSliderMarks: {
        0: '0',
        25: '25',
        50: '50',
        75: '75',
        100: '100'
      },
      horizontalSpeedSliderMarks: {
        1: '1',
        8: '8',
        12: '12'
      },
      verticalSpeedSliderMarks: {
        0.5: '0.5',
        1.0: '1.0',
        1.5: '1.5',
        2.0: '2.0'
      },
      returnSpeedSliderMarks: {
        1: '1',
        10: '10',
        12: '12',
      },
      corridorSliderMarks: {
        5: '5',
        10: '10',
        30: '30'
      },
      propsChecked: {
        panAngle: false,
        tiltAngle: false,
        zoom: false,
        horizontalSpeed: false,
        verticalSpeed: false,
        returnSpeed: false,
        corridorSize: false
      },
      invalidFields: [],
      availRecordModes: VALID_RECORD_MODES.map(mode => {
        return {
            'label': this.$t(`cameraSettings.recordModes.${mode}`),
            'value': mode
          }
      }),
      availThermalModes: VALID_THERMAL_MODES.map(mode => {
        return {
            'label': this.$t(`cameraSettings.thermalModes.${mode}`),
            'value': mode
          }
      }),
      lastValues: {
        panAngle: 0,
        tiltAngle: -30,
        zoom: 0,
        horizontalSpeed: 8,
        verticalSpeed: 2.0,
        returnSpeed: 8,
        corridorSize: 10,
        recordMode: 'videohd',
        thermalMode: 'rainbow',
        thermalRange: 'low',
        photoModeInterval: 5
      },
    };
  },
  mounted () {
    this.save_form = [...this.segment]
  },
  methods: {
    handleRecordModeChange(val) {
      this.form.forEach((s) => {
        s.recordMode = val;

        if (val == "thermal") {
          if (s.thermalMode === null) {
            s.thermalMode = this.lastValues.thermalMode
          }
          if (s.thermalRange === null) {
            s.thermalRange = this.lastValues.thermalRange
          }
        }
      })
    },
    handleThermalModeChange(val) {
      this.form.forEach((segments) => {
        segments.thermalMode = val;
      })
    },
    handleThermalRangeChange(val) {
      this.form.forEach((segments) => {
        segments.thermalRange = val;
      })
    },
    handleCheckboxChange(property, checked, callback) {
      this.propsChecked[property] = checked

      if (checked) {
        this.form.forEach(segment => { segment[property] = this.lastValues[property] })
      } else {
        if (this.form.length > 1) {
          this.form.forEach(segment => {
            segment[property] = this.save_form[0][property]
          })
        } else {
          this.form[0][property] = null
        }
      }

      if (callback) {
        callback()
      }
    },
    updatePanMarker () {
      this.form.forEach(segment => this.eventBus.$emit("update-pan-marker", segment))
    },
    updateCorridor () {
      if (this.form.length === 1) {
        this.form.forEach(segment => this.eventBus.$emit("update-corridor", segment))
      }
    },
    handlePropChange(prop, val, callback) {
      if (val === "" || isNaN(val) == true) {
        val = null
      }

      if (typeof(val) == "string") {
        if (val.slice(-1) == ".") {
          val = null
        }
      }

      if (prop == "panAngle" || prop == "tiltAngle" || prop == "corridorSize") {
        val = Math.floor(val)
      }

      this.form.forEach((segments) => {
        segments[prop] = val
      })

      if (callback) {
        callback()
      }
    },
    getValidateStatus(prop) {
      const idx = this.invalidFields.indexOf(prop);

      if (idx !== -1) {
        return "error";
      }
      return "";
    },
    saveProperties() {
      this.invalidFields = [];
      this.form.forEach((segments) => {
        if (segments.panAngle !== null && !isNorthAngleValid(segments.panAngle)) {
          this.invalidFields.push("panAngle");
        }
        if (segments.tiltAngle !== null && !isTiltValid(segments.tiltAngle)) {
          this.invalidFields.push("tiltAngle");
        }
        if (this.invalidFields.length === 0) {
          this.eventBus.$emit("save-props", segments);
          this.eventBus.$emit("remove-corridor", segments);
          this.eventBus.$emit("remove-pan-marker", segments);
        }
      })
    },
    cancel() {
      this.eventBus.$emit("cancel");
    },
  },
  watch: {
    segment: {
      immediate: true,
      handler: function(segmentFromProps) {
        if (segmentFromProps) {
          segmentFromProps.forEach((copy) => {
            this.form.push({...copy})
          })
          this.propsChecked = {
            panAngle: (this.form[0].panAngle !== null),
            tiltAngle: (this.form[0].tiltAngle !== null),
            zoom: (this.form[0].zoom !== null),
            horizontalSpeed: (this.form[0].horizontalSpeed !== null),
            verticalSpeed: (this.form[0].verticalSpeed !== null),
            returnSpeed: (this.form[0].returnSpeed !== null),
            corridorSize: (this.form[0].corridorSize !== null)
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ant-row,
.ant-row-flex {
  margin-bottom: 5px;
}

.save-button {
  display: inline;
  margin-left: 15px;
}

.cancel-button {
  display: inline;
  margin: 8px 0 8px 0;
}
.checkbox-column {
  width: 5%;
  text-align: left;
}
.title-column {
  width: 30%;
  text-align: left;
}
.commun-column {
  width: 20%;
  padding-left: 1%;
}
.duration-column {
  width: 25%;
  padding-left: 1%;
}
.info-column {
  width: 35%;
  padding-left: 7.5%;
}
.ant-input-number {
  width: 100%;
  text-align: center;
}
.ant-input {
  width: 100%;
}
</style>
