<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <defs>
      <rect id="my-location-b" x="8" y="8" width="12" height="12"/>
      <rect id="my-location-c" x="8" y="8" width="12" height="12"/>
      <circle id="my-location-a" cx="14" cy="14" r="8" />
    </defs>
    <Polygraph v-if="type === 'basepoint'"
      :total="basepoint"
      :fillColor="fillColor"
      :strokeColor="strokeColor"
    ></Polygraph>
    <!-- <g v-if="type === 'basepoint'" fill="none" fill-rule="evenodd">
      <use :fill="fillColor" xlink:href="#my-location-b"/>
      <rect x="8" y="8" width="12" height="12" :stroke="strokeColor" stroke-opacity="0.1" stroke-width="6" />
      <rect x="8" y="8" width="12" height="12" stroke-linejoin="square" stroke-width="2" />
    </g> -->
    <g v-else-if="type === 'waypoint'" fill="none" fill-rule="evenodd">
      <use :fill="fillColor" xlink:href="#my-location-c"/>
      <rect x="8" y="8" width="12" height="12" :stroke="strokeColor" stroke-opacity="0.1" stroke-width="6" />
      <rect x="8" y="8" width="12" height="12" stroke-linejoin="square" stroke-width="2" />
    </g>
    <g v-else-if="type === 'switchpoint'" fill="none" fill-rule="evenodd">
      <use :fill="fillColor" xlink:href="#my-location-c"/>
      <rect x="8" y="8" width="12" height="12" stroke="#FFF" stroke-opacity="0.8" stroke-width="2" />
      <rect x="8" y="8" width="12" height="12" stroke-linejoin="square" stroke-width="2" />
    </g>
    <g v-else-if="type === 'viewpoint'" fill="none" fill-rule="evenodd">
      <use :fill="fillColor" xlink:href="#my-location-a"/>
      <circle cx="14" cy="14" r="9" :stroke="strokeColor" stroke-opacity="0.1" stroke-width="6" />
      <circle cx="14" cy="14" r="7" stroke="#FFF" stroke-linejoin="square" stroke-width="2" />
    </g>
    <Polygraph 
      v-else-if="type === 'safepoint'"
      :total="safePoint"
      :fillColor="fillColor"
      :strokeColor="strokeColor"
    />
    <Polygraph v-else
      :total="fallbackpoint"
      :fillColor="fillColor"
      :strokeColor="strokeColor"
    ></Polygraph>
  </svg>
</template>

<script>
import Polygraph from "./PolygraphIcon.vue"

export default {
  props: [
    "fillColor",
    "strokeColor",
    "type",
  ],
  components: {
    Polygraph
  },
  data() {
    return {
      safePoint: 3,
      fallbackpoint: 8,
      basepoint: 8
    }
  }
}
</script>
