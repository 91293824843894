const Rt = 6371008.8; // default Earth radius in meters

function toRadians(angle) {
  return (Math.PI * angle) / 180
}

export function computeCorridorSegment(latLngA, latLngB, corridorSize) {
  let points = null;

  let distance = { lat: null, lng: null };
  distance.lng = ((latLngB.lng - latLngA.lng)/360) * 2 * Math.PI * Rt * Math.cos(toRadians(latLngA.lat));
  distance.lat = (latLngB.lat - latLngA.lat)/360 * 2 * Math.PI * Rt;

  let alpha;
  alpha =  Math.atan2(distance.lat, distance.lng);

  let D;
  D = Math.sqrt((distance.lng ** 2) + (distance.lat ** 2));

  let CaL = null, CaR = null, CbL = null, CbR = null, lat = 0, lng = 0;
  
  lat = latLngA.lat + (-corridorSize * Math.sin(alpha) + corridorSize * Math.cos(alpha)) * 360 / (2 * Math.PI * Rt);
  lng = latLngA.lng + (-corridorSize * Math.cos(alpha) - corridorSize * Math.sin(alpha)) * 360 / (2 * Math.PI * Rt * Math.cos(toRadians(latLngA.lat)));
  CaL = { lat: lat, lng: lng };

  lat = latLngA.lat + (-corridorSize * Math.sin(alpha) - corridorSize * Math.cos(alpha)) * 360 / (2 * Math.PI * Rt);
  lng = latLngA.lng + (-corridorSize * Math.cos(alpha) + corridorSize * Math.sin(alpha)) * 360 / (2 * Math.PI * Rt * Math.cos(toRadians(latLngA.lat)));
  CaR = { lat: lat, lng: lng };

  lat = latLngA.lat + ((D + corridorSize) * Math.sin(alpha) + corridorSize * Math.cos(alpha)) * 360 / (2 * Math.PI * Rt);
  lng = latLngA.lng + ((D + corridorSize) * Math.cos(alpha) - corridorSize * Math.sin(alpha)) * 360 / (2 * Math.PI * Rt * Math.cos(toRadians(latLngA.lat)));
  CbL = { lat: lat, lng: lng };

  lat = latLngA.lat + ((D + corridorSize) * Math.sin(alpha) - corridorSize * Math.cos(alpha)) * 360 / (2 * Math.PI * Rt);
  lng = latLngA.lng + ((D + corridorSize) * Math.cos(alpha) + corridorSize * Math.sin(alpha)) * 360 / (2 * Math.PI * Rt * Math.cos(toRadians(latLngA.lat)));
  CbR = { lat: lat, lng: lng };

  points = { 
    CaL: CaL,
    CaR: CaR,
    CbL: CbL,
    CbR: CbR 
  };

  return points;
}