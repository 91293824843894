const iconColors = {
    BASEPOINT: "#0099cc",
    VIEWPOINT: "#FF0000",
    WAYPOINT: "#ff7111",
    SWITCHPOINT: "#0099cc",
    SAFEPOINT: "#327B32",
    FALLBACKPOINT: "#FFFF00",
  };

export {
    iconColors
}