<template>
  <div class="segmentList-container" v-if="flightPlanId !== null">
    <a-row style="marginBottom: 20px">
      <a-col
        :md="{ span: 2, offset: 3 }"
        :lg="{ span: 2, offset: 2 }"
        :xl="{ span: 2, offset: (showMap ? 2 : 1) }"
        :xxl="{ span: 2, offset: 1 }"
      >
        <a-button @click="switchLayout">
          <a-icon type="border-horizontal" v-if="layout !== 'vertical'" />
          <a-icon type="border-verticle" v-else />
        </a-button>
      </a-col>
      <a-col
        :md="{ span: 3, offset: 14 }"
        :lg="{ span: 3, offset: (showMap ? 16 : 16) }"
        :xl="{ span: 3, offset: (showMap ? 16 : 16) }"
        :xxl="{ span: 3, offset: 18 }"
      >
        <a-button @click="showOrHideMap()">
          <a-icon :type="showMap ? 'right' : 'left'" />
          {{ showMap ? $t("common.hideMap") : $t("common.showMap") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row style="marginBottom: 20px" type="flex" justify="start">
      <a-col
        v-show="segmentsPropsTarget.length !== 0"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 22,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 18 : 18) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 19) : showMap ? 20 : 14,
          offset: layout === 'vertical' ? (showMap ? 4 : 3) : showMap ? 2 : 0,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 18) : 14,
          offset: layout === 'vertical' ? (showMap ? 1 : 3) : 0,
        }"
        :order="layout === 'vertical' ? 1 : showMap ? 3 : 2"
      >
        <segment-props-form
          v-if="segmentsPropsTarget.length !== 0"
          :segment="segmentsPropsTarget"
          :eventBus="eventBus"
          @cancel="() => onCancelSegmentProps()"
        ></segment-props-form>
      </a-col>
      <a-col
        v-show="segmentsPropsTarget.length === 0"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 19) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 3,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 20,
          offset: layout === 'vertical' ? (showMap ? 2 : 2) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 21 : 21) : showMap ? 20 : 19,
          offset: layout === 'vertical' ? (showMap ? 2 : 1) : showMap ? 2 : 3,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 21) : 21,
          offset: layout === 'vertical' ? (showMap ? 1 : 1) : 1,
        }"
        :order="layout === 'vertical' ? 1 : showMap ? 3 : 2"
      >
        <a-row style="marginBottom: 20px;" type="flex">
          <div class="gadgets-table">
            <a-col
              v-if="isFPManager"
              :md="6"
              :lg="6"
              :xl="8"
              :xxl="8"
              align="left"
            >
              <a-button
                :disabled="disableAddButton"
                class="btn btn-primary"
                type="primary"
                @click="addRow()"
                >{{ $t("form.add") }}</a-button
              >
             &#09;
              <a-dropdown
              :disabled="disableAddButton"
              :trigger="['click']">
                <a-button>
                  <a-icon style="color: #124c5a" type="more"/>
                </a-button>
                <a-menu slot="overlay">
                  <a-menu-item v-if="isFPManager" @click="properties_batch()">
                    <a-icon class="btn-icon" type="edit" />{{$t("points.properties")}}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
            <a-col
              :md="{ span: 8, offset: isFPManager ? 10 : 0 }"
              :lg="{ span: 8, offset: isFPManager ? 9 : 0 }"
              :xl="{ span: 12, offset: isFPManager ? 12 : 0 }"
              :xxl="{ span: 13, offset: isFPManager ? 11 : 0 }"
            >
              <a-select
                mode="multiple"
                :placeholder="$t('common.from')"
                style="width: 25%"
                @change="(val) => handleChangeFromPoint(val)"
              >
                <a-select-opt-group label="Basepoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'basepoint')"
                    :key="p.id"
                    :value="p.id"
                  >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="Viewpoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'viewpoint')"
                    :key="p.id"
                    :value="p.id"
                    >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="Waypoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'waypoint')"
                    :key="p.id"
                    :value="p.id"
                  >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
              </a-select>
              &#09;
              <a-select
                mode="multiple"
                :placeholder="$t('common.to')"
                style="width: 25%"
                @change="(val) => handleChangeToPoint(val)"
              >
                <a-select-opt-group label="Basepoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'basepoint')"
                    :key="p.id"
                    :value="p.id"
                  >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="Viewpoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'viewpoint')"
                    :key="p.id"
                    :value="p.id"
                    >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="Waypoint">
                  <a-select-option
                    v-for="p in points.filter((point) => point.pointType === 'waypoint')"
                    :key="p.id"
                    :value="p.id"
                  >{{ p.pointName }}</a-select-option>
                </a-select-opt-group>
              </a-select>
              <a-input-search
                style="width: 30%"
                class="input-search-table"
                :placeholder="$t('common.search')"
                v-model="searchQuery"
              />
            </a-col>
          </div>
        </a-row>

        <a-row>
          <a-table
            :row-selection="{ onChange: onSelectChange }"
            :columns="columns"
            :dataSource="filteredSegments"
            rowKey="id"
            :scroll="{ x: 975 }"
            :loading="dataLoaded ? false : true"
            :pagination="currentPage"
            @change="onTableChanged"
          >
            <template slot="segmentName" slot-scope="text, record, index">
              <a-form-item
                v-if="editingTarget && record.id === editingTarget.id"
                :validate-status="
                  invalidKeys.indexOf('segmentName') === -1 ? '' : 'error'
                "
              >
                <a-input
                  style="margin: -5px 0"
                  :value="editingTarget.segmentName"
                  @change="
                    (e) => handleChange(e.target.value, index, 'segmentName')
                  "
                  ref="segmentName"
                  @keyup.enter="() => save(editingTarget)"
                />
              </a-form-item>
              <template v-else>{{ text }}</template>
            </template>

            <template
              v-for="col in ['firstPointId', 'secondPointId']"
              :slot="col"
              slot-scope="value, record, index"
            >
              <div :key="col">
                <a-form-item
                  v-if="editingTarget && record.id === editingTarget.id"
                >
                  <a-select
                    show-search
                    style="width: 100%"
                    option-filter-prop="children"
                    :value="editingTarget[col]"
                    @change="(val) => handleChange(val, index, col)"
                  >
                    <a-select-opt-group label="Basepoint">
                      <a-select-option
                        v-for="p in col !== 'safePointId'
                          ? points.filter(
                              (point) => point.pointType === 'basepoint'
                            )
                          : safePoints"
                        :key="p.id"
                        :value="p.id"
                        :disabled="disablePointOption(col, editingTarget, p.id)"
                        >{{ p.pointName }}</a-select-option
                      >
                    </a-select-opt-group>
                    <a-select-opt-group label="Viewpoint">
                      <a-select-option
                        v-for="p in col !== 'safePointId'
                          ? points.filter(
                              (point) => point.pointType === 'viewpoint'
                            )
                          : safePoints"
                        :key="p.id"
                        :value="p.id"
                        :disabled="disablePointOption(col, editingTarget, p.id)"
                        >{{ p.pointName }}</a-select-option
                      >
                    </a-select-opt-group>
                    <a-select-opt-group label="Waypoint">
                      <a-select-option
                        v-for="p in col !== 'safePointId'
                          ? points.filter(
                              (point) => point.pointType === 'waypoint'
                            )
                          : safePoints"
                        :key="p.id"
                        :value="p.id"
                        :disabled="disablePointOption(col, editingTarget, p.id)"
                        >{{ p.pointName }}</a-select-option
                      >
                    </a-select-opt-group>
                    <a-select-opt-group label="Switchpoint">
                      <a-select-option
                        v-for="p in col !== 'safePointId'
                          ? points.filter(
                              (point) => point.pointType === 'switchpoint'
                            )
                          : safePoints"
                        :key="p.id"
                        :value="p.id"
                        :disabled="disablePointOption(col, editingTarget, p.id)"
                        >{{ p.pointName }}</a-select-option
                      >
                    </a-select-opt-group>
                  </a-select>
                </a-form-item>
                <span v-else>
                  {{ getPointNameById(value) }}
                </span>
              </div>
            </template>

            <template slot="safePointId" slot-scope="value, record, index">
              <div key="safePointId">
                <a-form-item
                  v-if="editingTarget && record.id === editingTarget.id"
                >
                  <a-select
                    show-search
                    style="width: 100%"
                    option-filter-prop="safepoints"
                    :filter-option="filterSafepoints"
                    :value="editingTarget['safePointId']"
                    @change="(val) => handleChange(val, index, 'safePointId')"
                  >
                    <a-select-option
                      v-for="p in safePoints"
                      :key="p.id"
                      :value="p.id"
                      :disabled="
                        disablePointOption('safePointId', editingTarget, p.id)
                      "
                      >{{ p.pointName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <span v-else>
                  {{ getPointNameById(value) }}
                </span>
              </div>
            </template>

            <template slot="action" slot-scope="text, record">
              <div class="btn-action">
                <span v-if="editingTarget && record.id === editingTarget.id">
                  <a-row class="btn-form">
                    <a-col :span="12">
                      <a-button
                        class="btn-editing"
                        @click="() => save(editingTarget)"
                        type="link"
                        :title="$t('form.save')"
                      >
                        <a-icon class="btn-icon editing-form" type="check" />
                      </a-button>
                    </a-col>
                    <a-col :span="12">
                      <a-button
                        class="btn-editing btn-cancel"
                        @click="() => cancel()"
                        type="link"
                        :title="$t('form.cancel')"
                      >
                        <a-icon class="btn-icon editing-form" type="close" />
                      </a-button>
                    </a-col>
                  </a-row>
                </span>
                <div v-else-if="editingTarget === null">
                  <a-dropdown :trigger="['click']">
                    <a-menu slot="overlay">
                      <a-menu-item v-if="isFPManager" @click="edit(record)"
                        ><a-icon class="btn-icon" type="edit" />{{
                          $t("form.edit")
                        }}
                      </a-menu-item>
                      <a-menu-item
                        v-if="isFPManager"
                        @click="confirmDelete(record)"
                        ><a-icon class="btn-icon" type="delete" />{{
                          $t("form.delete")
                        }}
                      </a-menu-item>
                      <a-menu-item @click="showProperties(record)"
                        ><a-icon class="btn-icon" type="plus" />{{
                          $t("points.properties")
                        }}
                      </a-menu-item>
                    </a-menu>
                    <a-button>
                      <a-icon style="color: #124c5a" type="more"/>
                    </a-button>
                  </a-dropdown>
                </div>
              </div>
            </template>

            <template slot="information" slot-scope="text, record">
              <div v-if="editingTarget === null">
                <span>
                  {{ $t("points.height") }}: {{ findMaxAltitude(record) }}
                  {{ $tc("units.meter", 2) }}
                </span>
              </div>
            </template>
          </a-table>
        </a-row>
      </a-col>

      <a-col
        :md="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 16 : 3,
          offset: layout === 'vertical' ? (showMap ? 4 : 1) : showMap ? 3 : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 17 : 3,
          offset: layout === 'vertical' ? (showMap ? 4 : 1) : showMap ? 2 : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 17 : 3,
          offset: layout === 'vertical' ? (showMap ? 3 : 1) : showMap ? 2 : 2,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 8 : 3) : showMap ? 17 : 3,
          offset: layout === 'vertical' ? (showMap ? 1 : 1) : showMap ? 0 : 2,
        }"
        :order="layout !== 'vertical' ? 1 : 2"
      >
        <a-row style="marginBottom: 8px;" >
          <a-col
            :md="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 5 : 0,
            }"
            :lg="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            :xl="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            :xxl="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            v-show="showMap"
          >
          </a-col>
        </a-row>
          <a-button @click="switchMap('map')" :disabled="type_map === 'map'">
            <a-icon type="global"/>
          </a-button>
          &#09;
          <a-button  @click="switchMap('graph-up') " :disabled="type_map === 'graph-up'">
            <a-icon type="caret-up" />
          </a-button>
          &#09;
          <a-button  @click="switchMap('graph-left')" :disabled="type_map === 'graph-left'">
            <a-icon type="caret-left" />
          </a-button>
        <a-row v-show="showMap">
          <segment-chart v-if="type_map !== 'map'"
            :eventBus="eventBus"
            :points="points"
            :safePoints="safePoints"
            :segments="segments"
            :editingTarget="editingTarget"
            :longi_latis=longi_lati
            />
          <segmentsMap v-if="type_map === 'map'"
            :eventBus="eventBus"
            :segments="segments"
            :points="points"
            :safePoints="safePoints"
            :editingTarget="editingTarget"
            :longi_latis=longi_lati
          />
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Table,
  Input,
  Select,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Icon,
  Modal
} from "ant-design-vue";
import SegmentPropsForm from "./SegmentPropsForm";
import SegmentsMap from "./SegmentsMap";
import { flightplanTypes } from "../../../store/mutation-types";
import { isFPManager } from "../../../utils/userAuth";
import SegmentChart from './SegmentChart';

Vue.use(Table);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Modal);

let flightPlansUnwatcher = null;
let currentFlightPlanIdUnwatcher = null;

export default {
  name: "segmentList",
  components: {
    SegmentPropsForm,
    SegmentsMap,
    SegmentChart,
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "segmentName",
          width: "18%",
          scopedSlots: { customRender: "segmentName" },
          sorter: (a, b) => a.segmentName.localeCompare(b.segmentName),
          sortOrder: sortedInfo.columnKey === "segmentName" && sortedInfo.order,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("segments.from"),
          dataIndex: "firstPointId",
          width: "18%",
          scopedSlots: { customRender: "firstPointId" },
        },
        {
          title: this.$t("segments.to"),
          dataIndex: "secondPointId",
          width: "18%",
          scopedSlots: { customRender: "secondPointId" },
        },
        {
          title: this.$t("segments.safepoint"),
          dataIndex: "safePointId",
          width: "18%",
          scopedSlots: { customRender: "safePointId" },
        },
        {
          title: this.$t("segments.information"),
          dataIndex: "information",
          width: "18%",
          scopedSlots: { customRender: "information" },
        },
        {
          dataIndex: "action",
          width: "10%",
          scopedSlots: { customRender: "action" },
        },
      ];
      return columns;
    },
    disableAddButton() {
      return this.editingTarget !== null;
    },
    flightPlanId() {
      return this.$store.state.flightPlans.currentFlightPlanId;
    },
    filteredSegments() {
      let result = []
      let filterFuncs = []

      if (this.searchPointsFrom.length) {
        filterFuncs.push((s) => {
          var pointCheck = []
            pointCheck = (this.searchPointsFrom.filter((point) => point === s.firstPointId))
            if (pointCheck.length > 0)
              return true
        })
      }
      if (this.searchPointsTo.length) {
        filterFuncs.push((s) => {
          var pointCheck = []
          pointCheck = (this.searchPointsTo.filter((point) => point === s.secondPointId))
              if (pointCheck.length > 0)
                return true
          })
      }

      if (this.searchQuery) {
        filterFuncs.push((s) => {
          var pointCheck = []
          pointCheck = this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => s.segmentName.toLowerCase().includes(v))
          if (pointCheck == true) {
            return true
          }
        })
      }

      let include = true

      this.segments.forEach((seg) => {
        include = true
        filterFuncs.forEach((func) => {
        if (include && !func(seg)) {
            include = false
          }
        })
          if (include)
            result.push(seg)
      })
      if (this.selectedSegment.length > 0) {
        var segments = []
        this.selectedSegment.forEach((s) => {
          result.forEach((r) => {
            if (r.id == s.id)
              segments.push(s)
          })
        })
        this.eventBus.$emit("show-searched-segments", this.segments, segments, this.points);
        return segments
      }
      else if (result) {
        if (this.type_map !== "map") 
          this.eventBus.$emit("search-selected-segments-chart", result);
        else {
          this.eventBus.$emit("show-searched-segments", this.segments, result, this.points);
        }
        return result
      } else {
        if (this.type_map !== "map")
          this.eventBus.$emit("search-selected-segments-chart", this.segments);
        else {
          this.eventBus.$emit("show-searched-segments", this.segments, this.segments, this.points);
        }
        return this.segments;
      }
    },
    points() {
      let points = this.$store.getters.getFlightPlanById(this.flightPlanId).points;
      return points.filter((p) => p.pointType !== "safepoint");
    },
    safePoints() {
      let points = this.$store.getters.getFlightPlanById(this.flightPlanId).points;
      return points.filter((p) => p.pointType === "safepoint");
    },
  },
  created() {
    flightPlansUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === flightplanTypes.FETCH_FLIGHTPLANS) {
        this.layout = this.$store.getters.getUserPreferences().layout
        this.refreshSegmentsFromStore();
        this.dataLoaded = true;
      }
    });

    currentFlightPlanIdUnwatcher = this.$store.watch(
      (state) => state.flightPlans.currentFlightPlanId,
      () => {
        this.refreshSegmentsFromStore();
        this.dataLoaded = true;
      }
    );
  },
  mounted() {
    if (this.flightPlanId) {
      if (this.$store.state.flightPlans.lastFetch === null) {
        this.$store.dispatch("fetchFlightPlans");
      } else {
        this.refreshSegmentsFromStore();
        this.dataLoaded = true;
      }
    }

    if (this.$store.state.preferences.lastFetch === null) {
      this.$store.dispatch("fetchUserPreferences");
    }
    this.showMap = this.$store.getters.getUserPreferences().showMap;

    this.eventBus.$on("save-first-point", this.onSaveFirstPoint);
    this.eventBus.$on("save-second-point", this.onSaveSecondPoint);
    this.eventBus.$on("save-safepoint", this.onSaveSafepoint);
    this.eventBus.$on("deselect-first-point", this.onDeselectFirstPoint);
    this.eventBus.$on("deselect-second-point", this.onDeselectSecondPoint);
    this.eventBus.$on("deselect-safe-point", this.onDeselectSafePoint);

    this.eventBus.$on("save-props", this.saveSegmentProps);
    this.eventBus.$on("cancel", this.onCancelSegmentProps);

    // this.eventBus.$on("map-clicked", this.onMapClicked);
    this.eventBus.$on("select-segment", this.onSelectSegment);
    this.eventBus.$on("deselect-segment", this.onDeselectSegment);

    this.layout = this.$store.getters.getUserPreferences().layout
  },
  beforeDestroy() {
    if (currentFlightPlanIdUnwatcher) {
      currentFlightPlanIdUnwatcher();
    }

    if (flightPlansUnwatcher) {
      flightPlansUnwatcher();
    }
  },
  data() {
    return {
      eventBus: new Vue(),
      dataLoaded: false,
      editingTarget: null,
      selectedSegment: [],
      selectedSegments: [],
      searchPointsFrom: [],
      searchPointsTo: [],
      queryFromTo: [],
      segmentsPropsTarget: [],
      stock_query: [],
      segments: [],
      currentPage: {
        defaultCurrent: 1,
        current: 1,
      },
      formData: {
        selectKey: undefined
      },
      searchQuery: null,
      invalidKeys: [],
      showMap: null,
      optionValues: ['Basepoint', 'Viewpoint', 'Waypoint'],
      filteredInfo: null,
      sortedInfo: null,
      layout: null,
      type_map: "map",
      longi_lati: 'longitude',
    };
  },
  methods: {
    handleChangeFromPoint(value) {
      this.searchPointsFrom = []
      this.searchPointsFrom = [...value]
    },

    handleChangeToPoint(value) {
      this.searchPointsTo = []
      this.searchPointsTo = [...value]
    },
    properties_batch() {
      this.segmentsPropsTarget = [...this.selectedSegments]
      this.showProperties(this.segmentsPropsTarget)
    },
    disableEditButton() {
      return this.selectedRowKeys.length == 0;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedSegments.length = 0
      this.selectedRowKeys.forEach((p) => { 
          this.selectedSegments.push(this.$store.getters.getCurrentSegmentById(this.$store.getters.getFlightPlanById(this.flightPlanId), p))
      })
    },
    switchMap(type_map) {
      this.formData.selectKey = undefined;
      if (type_map == 'map') {
        this.type_map = "map"
      }
      if (type_map === "graph-up") {
        this.longi_lati = "longitude"
        this.type_map = "graph-up"
      }
      if (type_map === "graph-left") {
        this.longi_lati = "latitude"
        this.type_map = "graph-left"
      }
    },
    switchLayout() {
      this.layout = this.layout === "vertical" ? "horizontal" : "vertical";
      this.$store.dispatch("setLayoutPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
              user: {...this.$store.getters.getCurrentUser()},
                            layout: this.layout,
      });
    },
    filterSafepoints(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    showOrHideMap() {
      this.showMap = this.showMap ? false : true;
    },
    getPointNameById(idPoint) {
      let point = this.points.find((p) => p.id === idPoint);
      if (point) {
        return point.pointName;
      } else {
        point = this.safePoints.find((sp) => sp.id === idPoint);
        if (point) {
          return point.pointName;
        }
      }
    },
    generateSegmentName(segment) {
      if (segment !== null &&
          segment.segmentName == "" &&
          segment.firstPointId !== null &&
          segment.secondPointId !== null) {
        const firstPointName = this.getPointNameById(segment.firstPointId);
        const secondPointName = this.getPointNameById(segment.secondPointId);
        segment.segmentName = `${firstPointName} -> ${secondPointName}`;
      }
    },
    onSelectSegment(segment) {
      this.selectedSegment = [...segment];
      this.selectedSegment.forEach((s) => {
        this.eventBus.$emit("change-segment-color", s, "editing");
      })
    },
    onDeselectSegment() {
      if (this.selectedSegment.length > 0) {
        this.selectedSegment.forEach((s) => {
          this.eventBus.$emit("change-segment-color", s, "fixed");
        })
        this.selectedSegment = [];
      }
    },
    onDeselectFirstPoint() {
      this.onDeselectSegment();
      if (this.editingTarget) {
        this.editingTarget = {
          ...this.editingTarget,
          firstPointId: null,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    onDeselectSecondPoint() {
      this.onDeselectSegment();
      if (this.editingTarget) {
        this.editingTarget = {
          ...this.editingTarget,
          secondPointId: null,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    onDeselectSafePoint() {
      this.onDeselectSegment();
      if (this.editingTarget) {
        this.editingTarget = {
          ...this.editingTarget,
          safePointId: null,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    onSaveFirstPoint(pointId) {
      this.onDeselectSegment();
      let firstPoint = this.points.find((p) => p.id === pointId);
      if (this.editingTarget && firstPoint) {
        this.editingTarget = {
          ...this.editingTarget,
          firstPointId: firstPoint.id,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    onSaveSecondPoint(pointId) {
      this.onDeselectSegment();
      let secondPoint = this.points.find((p) => p.id === pointId);
      if (this.editingTarget && secondPoint) {
        this.editingTarget = {
          ...this.editingTarget,
          secondPointId: secondPoint.id,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    onSaveSafepoint(safepointId) {
      this.onDeselectSegment();
      let safep = this.safePoints.find((sp) => sp.id === safepointId);
      if (this.editingTarget && safep) {
        this.editingTarget = {
          ...this.editingTarget,
          safePointId: safep.id,
        };
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    disablePointOption(type, record, value) {
      if (type === "firstPointId") {
        return record.secondPointId === value;
      } else if (type === "secondPointId") {
        return record.firstPointId == value;
      }
      return false;
    },
    findMaxAltitude(record) {
      let a1 = null;
      let a2 = null;

      this.points.forEach((p) => {
        if (p.id === record.firstPointId) {
          a1 = p.altitude;
        } else if (p.id === record.secondPointId) {
          a2 = p.altitude;
        }
      });

      return Math.max(a1, a2);
    },
    onTableChanged(pagination, filters, sorter) {
      if (this.currentPage.current !== pagination.current) {
        this.currentPage.current = pagination.current;
      }
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    refreshSegmentsFromStore() {
      if (this.flightPlanId) {
        this.segments = JSON.parse(
          JSON.stringify(
            this.$store.getters.getFlightPlanById(this.flightPlanId).segments
          )
        );
      }
    },
    refreshPointsFromStore() {
      if (this.flightPlanId) {
        this.points = JSON.parse(
          JSON.stringify(
            this.$store.getters.getFlightPlanById(this.flightPlanId).points
          )
        );
      }
    },
    handleChange(value, key, column) {
      if (this.editingTarget) {
        this.editingTarget[column] = value;
        this.eventBus.$emit("edit-segment", this.editingTarget);
      }
    },
    searchSegmentInTable(segment) {
      var chunks = [],
        i = 0;
      i = 0;
      while (i < this.segments.length) {
        chunks.push(this.segments.slice(i, (i += 10)));
      }
      i = 0;
      while (i < chunks.length) {
        let chunk = chunks[i],
          j = 0;
        while (j < chunk.length) {
          if (chunk[j].id === segment.id) {
            return i + 1;
          }
          j++;
        }
        i++;
      }
    },
    edit(target) {
      this.onDeselectSegment();
      delete target.firstPoint, target.secondPoint, target.safePoint;
      this.editingTarget = { ...target };
      this.currentPage.current = this.searchSegmentInTable(this.editingTarget);
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store
            .dispatch("deleteSegment", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              segment: target,
            })
            .then(() => this.refreshSegmentsFromStore());
        },
        onCancel: () => {},
      });
    },
    validateSegment(segment) {
      let invalidKeys = [];

      if (segment.segmentName === "") {
        invalidKeys.push("segmentName");
      }

      this.invalidKeys = invalidKeys;

      return invalidKeys.length === 0;
    },
    save(segment) {
      this.onDeselectSegment();
      if (Object.hasOwnProperty.call(segment, "id")) {
        this.generateSegmentName(segment);

        if (this.validateSegment(segment)) {
          let isNew = segment.id === "new";
          let promise;

          if (isNew) {
            promise = this.$store.dispatch("addSegment", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              segment,
            });
          } else {
            promise = this.$store.dispatch("updateSegment", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              segment,
            });
          }

          promise.then(() => {
            this.editingTarget = null;
//            this.segmentPropsTarget = null;
            this.segmentsPropsTarget = [];
            this.refreshSegmentsFromStore();
          });
        }
      }
    },
    cancel() {
      this.onDeselectSegment();
      this.eventBus.$emit("change-segment-color", this.editingTarget, "fixed");
      this.editingTarget = null;
      this.invalidKeys = [];
      let index = this.segments.findIndex((segment) => segment.id === "new");
      if (index > -1) {
        this.segments.splice(index, 1);
      }
    },
    addRow() {
      this.onDeselectSegment();
      this.currentPage.current = 1;
      this.resetSorter();

      const newSegment = {
        id: "new",
        segmentName: "",
        safePointId: null,
        firstPointId: null,
        secondPointId: null,
        panAngle: null,
        tiltAngle: null,
        speed: null,
        corridorSize: null,
        recordMode: null,
        photoModeInterval: null,
        thermalMode: null,
        thermalRange: null
      };

      this.segments.unshift(newSegment);
      this.editingTarget = newSegment;
      this.$nextTick(() => {
        if (this.$refs["segmentName"]) {
          this.$refs["segmentName"].focus();
        }
      });
    },
    resetSorter() {
      this.filteredInfo = null;
      this.sortedInfo = null;
    },
    showProperties(target) {
      this.onDeselectSegment();
      var array = []
      if (Array.isArray(target) == false) {
        array.push(target)
     }
     else {
       array = target
     }
      if (this.editingTarget && array.id === this.editingTarget.id) {
          this.segmentsPropsTarget = [...this.editingTarget]
      } else {
        this.segmentsPropsTarget = [...array]
      }

      this.segmentsPropsTarget.forEach((p) => {
        if (this.segmentsPropsTarget.length < 2)
          this.eventBus.$emit("draw-corridor", p);
        this.eventBus.$emit("draw-pan-marker", p);
      })
    },
    saveSegmentProps(props) {
      this.onDeselectSegment();
      if (this.editingTarget === null) {
        const sIdx = this.segments.findIndex(
          (s) => s.id === props.id
        );

        if (sIdx !== -1) {
          this.segments[sIdx] = props;
          this.save(props);
        }
      } else {
        this.editingTarget = { ...props };
      }
      this.segmentsPropsTarget = [];
    },
    onCancelSegmentProps() {
      this.onDeselectSegment();
      this.segmentsPropsTarget.forEach((segmentProps) => {
        this.eventBus.$emit("remove-corridor", segmentProps);
        this.eventBus.$emit("remove-pan-marker", segmentProps);
      })

      this.editingTarget = null;
      this.segmentsPropsTarget = [];
    },
  },
};
</script>

<style lang="less" scoped>
.segmentList-container {
  width: 100%;
}

.gadgets-table {
  width: 100%;
  .input-search-table {
    width: 100%;
    margin-left: 15%;
  }
}

.segmentList-table {
  width: 105%;
}

.ant-select {
  width: 150px;
}

.btn-action {
  .btn-editing {
    align-items: center;
  }
  .btn-form {
    height: 2.5rem;
    width: 3.75rem;
  }
  .editing-form {
    font-size: 1.5rem;
  }
  .btn-cancel {
    color: red;
  }
  .btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }
}

.drawer-content {
  height: 20rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: left;
  margin-top: 3rem;
  padding: 0 10rem 0 5.5rem;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}

.ant-table {
  display: block;
  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }
    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }
        &:last-of-type {
          padding-bottom: 1rem;
        }
      }
      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;
        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
</style>
