<template>
	<div id="app">
		<svg id="chart" viewBox="0 0 500 500"></svg>
	</div>
</template>

<script>
import * as d3 from 'd3';
import { iconColors } from "../../MarkerIcon/iconColors"

let chart = []
let yAxis = []
let lines = []
let x = []
let y = []

function updateMapPosition(e) {
	chart.selectAll('circle')
		.attr('x', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('y', d => y(d.y) * e.transform.k + e.transform.y)
		.attr('cx', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('cy', d => y(d.y) * e.transform.k + e.transform.y)

	chart.selectAll("#rectangle")
		.attr('x', d => (x(d.x) * e.transform.k + e.transform.x) - 5)
		.attr('y', d => (y(d.y) * e.transform.k + e.transform.y) - 5)

	var newX = e.transform.rescaleX(x);
	var newY = e.transform.rescaleY(y);

	yAxis.call(d3.axisLeft(newY))

	chart.selectAll("#triangle")
		.attr('x', d => x(d.x) * e.transform.k + e.transform.x)
		.attr('y', d => (y(d.y) * e.transform.k + e.transform.y))
		.attr("transform", d => `translate(${newX(d.x)}, ${newY(d.y)})`)


	lines.forEach((line) => {
		line.attr("transform", e.transform)
			.attr("stroke-width", 1 / e.transform.k)

	})
}

function handleMouseOverPoint(e) {
	chart
		.append("rect")
		.attr("width", 10 * e.explicitOriginalTarget.__data__.name.length)
		.attr("height", 15)
		.style("fill", "white")
		.style("stroke", "black")
		.style("stroke-width", 0.2)
		.style("pointer-events", "all")
		.attr("x", (e.explicitOriginalTarget.attributes.x.value) - e.explicitOriginalTarget.__data__.name.length * 4.5)
		.attr("y", (e.explicitOriginalTarget.attributes.y.value) - 25)
		.attr("id", "rect_point_hover")
	chart
		.append("text")
		.attr("font-size", 10)
		.attr("text-anchor", "middle")
		.attr("id", "name_point_hover")
		.attr("x", (e.explicitOriginalTarget.attributes.x.value) + 4)
		.attr("y", (e.explicitOriginalTarget.attributes.y.value) - 15)
		.text(e.explicitOriginalTarget.__data__.name);
}

function handleMouseOutPoint() {
	chart.selectAll("#" + "name_point_hover")
		.remove()
	chart.selectAll("#" + "rect_point_hover")
		.remove()
}

function equalToEventTarget(d) {
	if (this !== d.target)
		return false
	return this == d.target;
}


export default {
	name: 'pointstest',
	props: {
		eventBus: {
			type: Object
		},
		points: Array,
		segments: Array,
		longi_latis: String,
	},
	data() {
		return {
			data: [],
			viewpoint: [],
			fallback: [],
			safepoint: [],
			basepoint: [],
			waypoint: [],
			switchpoint: [],
			lines: [],
			points_search: [],
			segments_search: [],
			size: 5,
			longi_lati: "longitude",
			prec_id: [],
			type_vision: true,
		}
	},
	watch: {
		segments: {
						deep: true,
			handler() {
				this.resetMap()
			}

		},
		longi_latis: {
			deep: true,
			handler() {
				this.longi_lati = this.longi_latis
				this.resetMap()
			}
		},
		points_search: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		segments_search: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		type_vision: {
			deep: true,
			handler() {
				this.resetMap()
			}
		},
		flightplanPoints: {
			deep: true,
			handler() {
				this.resetMap()
			},
		}
	},

	mounted() {
		this.longi_lati = this.longi_latis
		this.eventBus.$on("show-selected-points", this.onShowSelectedPoints);
		this.eventBus.$on("search-selected-segments-chart", this.onSearchSelectedSegments);

		this.flightplanSegments.forEach(segment => {
			this.segments_search.push(segment)
		})
		this.createChart()
	},

	computed: {
		flightplanPoints() {
			return this.$store.getters.getCurrentFlightPlan().points;
		},
		flightplanSegments() {
			return this.segments;
		},
		flightplanPointsSearch() {
			return this.points_search;
		},
	},

	methods: {
		resetMap() {
			this.data = []
			this.viewpoint = []
			this.fallback = []
			this.safepoint = []
			this.basepoint = []
			this.waypoint = []
			this.switchpoint = []
			lines = []
			chart.selectAll("*").remove();
			this.createChart()
		},

		onShowSelectedPoints(d) {
			this.points_search = d
		},
		onSearchSelectedSegments(d) {
			this.segments_search = d
		},
		createChart() {
			const width = 500;
			const height = 500;
			const margin = {
				top: 20,
				right: 20,
				bottom: 50,
				left: 60
			};
			chart = d3.select("#chart")
				.append("g")
				.attr("transform", `translate(${margin.left}, ${margin.top})`)
			var zoom = d3.zoom()
				.scaleExtent([.5, 20])
				.extent([
					[0, 0],
					[width, height]
				])
				.on("zoom", updateMapPosition);
			chart
				.append("rect")
				.attr("width", width)
				.attr("height", height)
				.style("fill", "none")
				.style("pointer-events", "all")
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
				.on('click', this.filterUnSelectSegments)
				.call(zoom);

			x = d3.scaleLinear()
				.range([0, width - margin.left - margin.right]);

			y = d3.scaleLinear()
				.range([height - margin.top - margin.bottom, 0]);

			yAxis = chart.append("g");

			chart.append("text")
				.attr("font-size", 10)
				.attr("text-anchor", "middle")
				.attr("transform", "rotate(-90)")
				.attr("x", 0 - ((height - margin.top - margin.bottom) / 2))
				.attr("y", -40)
				.text("Altitude");

			this.createSegments();
			this.createPoints();
		},

		filterSelectedSegments(d) {
			this.flightplanSegments.forEach((segment) => {
				if (d.originalTarget.attributes.p.nodeValue === segment.segmentName) {
					var segments_same = []
					this.segments.forEach(s => {
						if ((segment.firstPointId == s.firstPointId && segment.secondPointId == s.secondPointId) || (segment.firstPointId == s.secondPointId && segment.secondPointId == s.firstPointId))
							segments_same.push(s)
					})
					this.eventBus.$emit("select-segment", segments_same);
				}
			});
			if (this.prec_id !== null)
				this.prec_id.forEach((precedent) => {
					chart.selectAll("#" + precedent)
						.attr("stroke", "#F0DE00")
				})
			chart.selectAll("path")._groups[0].forEach((a) => {
				if (a.attributes.p !== undefined && d.originalTarget.attributes.p.value === a.attributes.p.value) {
					chart.selectAll("#" + a.attributes.p.ownerElement.id)
						.attr("stroke", "#43eb34")
					this.prec_id.push(a.attributes.p.ownerElement.id)
				}
			})
			chart.selectAll("#" + d.originalTarget.attributes.id.value)
				.attr("stroke", "#43eb34")
			this.prec_id.push(d.originalTarget.attributes.id.value)
		},

		filterUnSelectSegments(d) {
			var tooltipWithContent = d3.selectAll("#path");
			var outside = tooltipWithContent.filter(equalToEventTarget).empty()
			if (outside) {
				this.eventBus.$emit("deselect-safe-point");
				this.prec_id.forEach((precedent) => {
					chart.selectAll("#" + precedent)
						.attr("stroke", "#F0DE00")
				})
			}
		},

		createSegments() {
			this.flightplanPoints.forEach((id) => {
				this.data.push({ y: id.altitude, x: id[this.longi_lati] })
			})
			this.data.sort((a, b) => {
				return a.x - b.x;
			});
			if (this.data[0] === undefined)
				x.domain([0, 10]);
			else
				x.domain([this.data[0].x - 0.00005, this.data[this.data.length - 1].x + 0.00005]);
			y.domain([-5, 100]);
			yAxis.call(d3.axisLeft(y));

			var i = 0;
			var selected_segments = []
			this.flightplanSegments.forEach(segment => {
				for (i = 0; this.segments_search.length > i; i++)
					if (this.segments_search[i].segmentName == segment.segmentName) {
						selected_segments.push(segment)
					}
			})
			selected_segments.forEach((segment) => {
				var first
				var second
				var points
				this.flightplanPoints.forEach((segmentPoints) => {
					if (segment.firstPointId === segmentPoints.id)
						first = segmentPoints
					if (segment.secondPointId === segmentPoints.id)
						second = segmentPoints
				})
				var curvebefore = d3.line().curve(d3.curveStepBefore);
				var curveafter = d3.line().curve(d3.curveStepAfter);

				if (first.pointType == "basepoint") {
					points = [
						[x(first[this.longi_lati]), y(0)],
						[x(first[this.longi_lati]), y(first.altitude)]
					];
					lines[i] = chart
						.append('path')
						.attr('d', curveafter(points))
						.attr('p', segment.segmentName)
						.attr("id", "id" + i)
						.attr('stroke', "#F0DE00")
						.attr('fill', 'none')
						.on('click', this.filterSelectedSegments)
					i++

					lines[i] = chart.append("line")
						.attr("x1", x(5000))
						.attr("x2", x(-5000))
						.attr("y1", y(0))
						.attr("y2", y(0))
						.style("stroke-dasharray", "5,5")
						.style("stroke", "black")

					i++
				}
				if (second.pointType == "basepoint") {
					points = [
						[x(second[this.longi_lati]), y(0)],
						[x(second[this.longi_lati]), y(second.altitude)]
					];
					lines[i] = chart
						.append('path')
						.attr('d', curveafter(points))
						.attr('p', segment.segmentName)
						.attr("id", "id" + i)
						.attr('stroke', "#F0DE00")
						.attr('fill', 'none')
						.on('click', this.filterSelectedSegments)
					i++
				}

				if (first.altitude > second.altitude) {
					points = [
						[x(first[this.longi_lati]), y(first.altitude)],
						[x(second[this.longi_lati]), y(second.altitude)]
					];
					lines[i] = chart
						.append('path')
						.attr('d', curveafter(points))
						.attr('p', segment.segmentName)
						.attr("id", "id" + i)
						.attr('stroke', "#F0DE00")
						.attr('fill', 'none')
						.on('click', this.filterSelectedSegments)
					i = i + 1
				} else {
					points = [
						[x(first[this.longi_lati]), y(first.altitude)],
						[x(second[this.longi_lati]), y(second.altitude)]
					];
					lines[i] = chart
						.append('path')
						.attr('d', curvebefore(points))
						.attr('p', segment.segmentName)
						.attr("id", "id" + i)
						.attr('stroke', "#F0DE00")
						.attr('fill', 'none')
						.on('click', this.filterSelectedSegments)
					i = i + 1
				}
			});
		},

		createPoints() {
						var select = this.flightplanPointsSearch
			var selected_points = []
			if (select.length > 0) {
				this.flightplanPoints.forEach(point => {
					if (select.includes(point.pointType))
						selected_points.push(point)
				})
			} else {
				selected_points = this.flightplanPoints.map(item => ({ ...item }))
			}

			selected_points.forEach((point) => {
				if (point.pointType === "viewpoint")
					this.viewpoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName })
				if (point.pointType === "fallbackpoint")
					this.fallback.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName })
				if (point.pointType === "safepoint")
					this.safepoint.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName })
				if (point.pointType === "basepoint")
					this.basepoint.push({ y: 0, x: point[this.longi_lati], type: point.pointType, name: point.pointName })
				if (point.pointType === "waypoint")
					this.waypoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName })
				if (point.pointType === "switchpoint")
					this.switchpoint.push({ y: point.altitude, x: point[this.longi_lati], type: point.pointType, name: point.pointName })

			});
			chart.selectAll()
				.data(this.viewpoint)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', 'red')
				.attr('stroke', iconColors.VIEWPOINT)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);


			chart.selectAll()
				.data(this.fallback)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', '#fefe00')
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);


			chart.selectAll()
				.data(this.basepoint)
				.enter()
				.append('circle')
				.attr('x', d => x(d.x))
				.attr('y', d => y(d.y))
				.attr('cx', d => x(d.x))
				.attr('cy', d => y(d.y))
				.attr('r', 5)
				.attr('fill', iconColors.BASEPOINT)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.waypoint)
				.enter()
				.append('rect')
				.attr("x", d => x(d.x) - 5)
				.attr("y", d => y(d.y) - 5)
				.attr("id", 'rectangle')
				.attr("width", 10)
				.attr("height", 10)
				.attr('fill', iconColors.WAYPOINT)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.switchpoint)
				.enter()
				.append('rect')
				.attr("x", d => x(d.x) - 5)
				.attr("y", d => y(d.y) - 5)
				.attr("id", 'rectangle')
				.attr("width", 10)
				.attr("height", 10)
				.attr('fill', iconColors.SWITCHPOINT)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);

			chart.selectAll()
				.data(this.safepoint)
				.enter()
				.append('path')
				.attr("x", d => x(d.x))
				.attr("y", d => y(d.y))
				.attr("transform", d => `translate(${x(d.x)}, ${y(d.y)})`)
				.attr("id", 'triangle')
				.attr('d', d3.symbol().type(d3.symbolTriangle))
				.attr('fill', iconColors.SAFEPOINT)
				.on("mouseover", handleMouseOverPoint)
				.on("mouseout", handleMouseOutPoint);
		},
	}
};
</script>

<style>

</style>