<script>
import Vue from "vue";
import Gmaps from "../../Gmaps";
import { defaultMapCenter } from "../../Gmaps";
import { getColoredIconUrl } from "../../../utils/IconUrlGenerator";
import { computeCorridorSegment } from "../../../utils/corridorComputing";
import { isFPManager } from "../../../utils/userAuth";
import svgPanMarker from '!!raw-loader!../../../assets/inline/panMarker.svg';
import { iconColors } from "../../MarkerIcon/iconColors"

let corridorPolygons = [];
let clickListener = null;

let segmentWatched = null;

var startPointIdClick = null;
var endPointIdClick = null;
var safePointIdClick = null;

const segmentColors = {
  EDITING: "#43eb34",
  FIXED: "#F0DE00",
};

function setInfoContent(point) {
  let markup = `
    <div class="info-content">
      <h1 class="marker-info">
        ${point.pointName}
      </h1>
      <div class="body-content">
        <p> <b>Type:</b> ${point.pointType} </p>
        <p> <b>Latitude:</b> ${Number.parseFloat(
          point.latitude.toFixed(6)
        )} </p>
        <p> <b>Longitude:</b> ${Number.parseFloat(
          point.longitude.toFixed(6)
        )} </p>
        <p> <b>Altitude:</b> ${point.altitude} </p>
      </div>
    </div>
  `;
  return markup;
}

function numAverage(a) {
  var b = a.length,
      c = 0, i;
  for (i = 0; i < b; i++){
    c += Number(a[i]);
  }
  return c/b;
}

function createPanMarkerUrl(rotation) {
  return `data:image/svg+xml;charset=utf-8,
         ${encodeURIComponent(svgPanMarker.replace(/transform.+/, `transform="rotate(${rotation})"`))}`
}

export default {
  name: "segmentsMap",
  mixins: [Gmaps],
  props: {
    eventBus: Object,
    segments: Array,
    editingTarget: Object,
    points: Array,
    safePoints: Array,
  },
  data() {
    return {
      markers: [],
      segmentPolylines: [],
      segmentPanMarkers: []
    };
  },
  computed: {
    flightPlan() {
      return this.$store.getters.getCurrentFlightPlan();
    },
  },
  methods: {
    resetMapConfig() {
      const basePoint = this.points.find((p) => p.pointType === "basepoint");
      const mapCenter = basePoint
        ? { lat: basePoint.latitude, lng: basePoint.longitude }
        : defaultMapCenter;
      this.map.setZoom(20);
      this.map.setCenter(mapCenter);
    },
    onFlightplanChanged() {
      Vue.nextTick().then(() => {
        this.resetMapConfig();
        this.resetPoints();
        this.resetSegments();
      });
    },
    resetSegments() {
      this.removeAllSegments();
      this.segments.forEach((segment) => {
        this.addSegment(segment);
      });
    },
    resetPoints() {
      this.removeAllPoints();
      this.points.forEach((p) => {
        this.addMarker(p);
      });
      this.safePoints.forEach((safePoint) => {
        this.addMarker(safePoint);
      });
    },
    addSegment(segment) {
      let path = this.createPath(segment);

      let pathPolyline = path.pathPolyline;
      let polyline = new this.google.maps.Polyline({
        path: pathPolyline,
        strokeColor: segmentColors.FIXED,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        map: this.map,
      });
      var segments = []
      this.segments.forEach(s => {
        if ((segment.firstPointId == s.firstPointId && segment.secondPointId == s.secondPointId) || (segment.firstPointId == s.secondPointId && segment.secondPointId == s.firstPointId))
          segments.push(s)
      })
     polyline.addListener("click", () => {
        this.eventBus.$emit("select-segment", segments);
      });

      let pathPolygon = path.pathPolygon;
      let polygon = new this.google.maps.Polygon({
        path: pathPolygon,
        strokeColor: "#4C724C",
        fillColor: "#7FBF7F",
        strokeOpacity: 0.3,
        strokeWeight: 3,
        map: this.map,
        visible: false,
      });

      let segmentPolylineObj = {
        segment: segment,
        polyline: polyline,
        polygon: polygon,
      };
      this.segmentPolylines.push(segmentPolylineObj);
    },
    createPath(segment) {
      let path = {
        pathPolyline: [],
        pathPolygon: [],
      };
      let firstPoint, secondPoint, safePoint;
      if (segment.firstPointId && segment.secondPointId) {
        let markerObj = this.markers.find((m) => m.point.id == segment.firstPointId);
        if (markerObj) {
          firstPoint = new this.google.maps.LatLng(
            parseFloat(markerObj.point.latitude),
            parseFloat(markerObj.point.longitude)
          );
        }
        markerObj = this.markers.find((m) => m.point.id == segment.secondPointId);
        if (markerObj) {
          secondPoint = new this.google.maps.LatLng(
            parseFloat(markerObj.point.latitude),
            parseFloat(markerObj.point.longitude)
          );
        }
        path.pathPolyline = [firstPoint, secondPoint];

        if (segment.safePointId) {
          markerObj = this.markers.find((m) => m.point.id == segment.safePointId);
          if (markerObj) {
            safePoint = new this.google.maps.LatLng(
              parseFloat(markerObj.point.latitude),
              parseFloat(markerObj.point.longitude)
            );
          }
          path.pathPolygon = [firstPoint, secondPoint, safePoint, firstPoint];
        }
      }

      return path;
    },
    removeSegment(segment) {
      const polylineSegmentIndex = this.segmentPolylines.findIndex(
        (sp) => sp.segment.id === segment.id
      );
      if (polylineSegmentIndex !== -1) {
        this.google.maps.event.clearInstanceListeners(this.segmentPolylines[polylineSegmentIndex].polyline)
        this.segmentPolylines[polylineSegmentIndex].polyline.setMap(null);
        this.segmentPolylines[polylineSegmentIndex].polygon.setMap(null);
        this.segmentPolylines.splice(polylineSegmentIndex, 1);
      }
    },
    removeAllSegments() {
      this.segmentPolylines.forEach((segmentPolylineObj) => {
        segmentPolylineObj.polyline.setMap(null);
        this.google.maps.event.clearInstanceListeners(
          segmentPolylineObj.segment
        );
        segmentPolylineObj.polygon.setMap(null);
      });
      this.segmentPolylines = [];

      this.segmentPanMarkers.forEach(m => {
        m.marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(m.segment);
      })
      this.segmentPanMarkers = [];
    },
    addMarker(point) {
      var marker = new this.google.maps.Marker({
        position: {
          lat: point.latitude,
          lng: point.longitude,
        },
        icon: {
          url: getColoredIconUrl(
            iconColors[point.pointType.toUpperCase()],
            "#333333",
            point.pointType
          ),
          anchor: { x: 14, y: 14 },
        },
        map: this.map,
        draggable: false,
        title: point.pointName + " / " + "Altitude : " + point.altitude + "m",
      });
      let markerObj = {
        point: point,
        marker: marker,
      };
      this.markers.push(markerObj);
    },
    addInfoWindowListener(marker, point) {
      var infowindow = new this.google.maps.InfoWindow();
      marker.addListener("mouseover", function() {
        infowindow.setContent(setInfoContent(point));
        infowindow.open(this.map, marker);
      });
      marker.addListener("mouseout", function() {
        infowindow.close();
      });
    },
    addClickListener(markerObj) {
      let marker = markerObj.marker;
      marker.addListener("click", () => {
        if (markerObj.point.pointType === "safepoint") {
          if (safePointIdClick === null) {
            safePointIdClick = markerObj.point.id;
            this.eventBus.$emit("save-safepoint", safePointIdClick);
          } else if (safePointIdClick !== null) {
            if (markerObj.point.id === safePointIdClick) {
              safePointIdClick = null;
              this.eventBus.$emit("deselect-safe-point");
            }
          }
        } else if (markerObj.point.pointType !== "fallbackpoint") {
          if (startPointIdClick === null) {
            if (
              endPointIdClick === null ||
              endPointIdClick !== markerObj.point.id
            ) {
              startPointIdClick = markerObj.point.id;
              this.eventBus.$emit("save-first-point", startPointIdClick);
            } else {
              endPointIdClick = null;
              this.eventBus.$emit("deselect-second-point");
            }
          } else if (startPointIdClick && !endPointIdClick) {
            if (startPointIdClick !== markerObj.point.id) {
              endPointIdClick = markerObj.point.id;
              this.eventBus.$emit("save-second-point", endPointIdClick);
            } else {
              startPointIdClick = null;
              this.eventBus.$emit("deselect-first-point");
            }
          } else if (startPointIdClick && endPointIdClick) {
            if (markerObj.point.id === startPointIdClick) {
              startPointIdClick = null;
              this.eventBus.$emit("deselect-first-point");
            } else if (markerObj.point.id === endPointIdClick) {
              endPointIdClick = null;
              this.eventBus.$emit("deselect-second-point");
            }
          }
        }
      });
    },
    onChangeSegmentColor(segment, state) {
      this.segmentPolylines.forEach((segmentObj) => {
        if (segmentObj.segment.id === segment.id) {
          segmentObj.polyline.setOptions({
            strokeColor: segmentColors[state.toUpperCase()],
          });
        } else {
          segmentObj.polyline.setOptions({
            strokeColor: segmentColors.FIXED,
          });
        }
      });
    },
    onEditingSegment(segment) {
      var segmentObj = this.segmentPolylines.find(
        (s) => s.segment.id === segment.id
      );
      if (segmentObj) {
        let newPath = this.createPath(segment);
        segmentObj.polyline.setPath(newPath.pathPolyline);
        if (newPath.pathPolyline.length === 2) {
          segmentObj.polyline.setVisible(true);
          this.onChangeSegmentColor(segment, "editing");
        }
        segmentObj.polygon.setPath(newPath.pathPolygon);
        if (newPath.pathPolygon.length === 4 || newPath.pathPolyline.length === 4 ) {
          segmentObj.polygon.setVisible(true);
        }
        segmentObj.segment = segment;
      }
    },
    showMarkersEdition(segment) {
      let firstPointIndex, secondPointIndex, safePointIndex;
      firstPointIndex = this.markers.findIndex(
        (m) => m.point.id === segment.firstPointId
      );
      if (firstPointIndex !== -1) {
        this.markers[firstPointIndex].marker.setVisible(true);
      }
      secondPointIndex = this.markers.findIndex(
        (m) => m.point.id === segment.secondPointId
      );
      if (secondPointIndex !== -1) {
        this.markers[secondPointIndex].marker.setVisible(true);
      }
      safePointIndex = this.markers.findIndex(
        (m) => m.point.id === segment.safePointId
      );
      if (safePointIndex !== -1) {
        this.markers[safePointIndex].marker.setVisible(true);
      }
    },
    refreshSegmentPolyline(segment) {
      const segmentObjIndex = this.segmentPolylines.findIndex(
        (s) => s.segment.id === segment.id
      );
      if (segmentObjIndex !== -1) {
        let newPath = this.createPath(
          this.segmentPolylines[segmentObjIndex].segment
        );
        this.onChangeSegmentColor(segment, "editing");
        this.segmentPolylines[segmentObjIndex].polyline.setPath(
          newPath.pathPolyline
        );
        this.segmentPolylines[segmentObjIndex].polyline.setVisible(true);
        this.segmentPolylines[segmentObjIndex].polygon.setPath(
          newPath.pathPolygon
        );
        this.segmentPolylines[segmentObjIndex].polygon.setVisible(true);
        this.segmentPolylines[segmentObjIndex].segment = segment;
      }
    },
    removeAllPoints() {
      this.markers.forEach((m) => {
        m.marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(m.marker);
      });
      this.markers = [];
    },
    removePoint(point) {
      const markerObjIndex = this.markers.findIndex(
        (marker) => marker.point.id === point.id
      );
      if (markerObjIndex !== -1) {
        this.markers[markerObjIndex].marker.setMap(null);
        this.markers.splice(markerObjIndex, 1);
      }
    },
    searchLatLngPoint(pointId) {
      let pointIndex = this.points.findIndex((p) => p.id === pointId);
      if (pointIndex > -1) {
        let LatLng = {
          lat: this.points[pointIndex].latitude,
          lng: this.points[pointIndex].longitude,
        };
        return LatLng;
      } else {
        return null;
      }
    },
    toLatLng(latLon) {
      return new this.google.maps.LatLng(
        parseFloat(latLon.lat),
        parseFloat(latLon.lng)
      );
    },
    createCorridorPath(corridorPoints) {
      let CaL = this.toLatLng(corridorPoints.CaL);
      let CaR = this.toLatLng(corridorPoints.CaR);
      let CbL = this.toLatLng(corridorPoints.CbL);
      let CbR = this.toLatLng(corridorPoints.CbR);

      return [CaL, CbL, CbR, CaR, CaL];
    },
    onDrawCorridor(segment) {
      this.onChangeSegmentColor(segment, "editing");
      let corridorObj = corridorPolygons.find(
        (cp) => cp.segment.id === segment.id
      );
      if (!corridorObj) {
        let latLngA, latLngB, corridorSize;
        latLngA = this.searchLatLngPoint(segment.firstPointId);
        latLngB = this.searchLatLngPoint(segment.secondPointId);
        if (latLngA && latLngB) {
          if (segment.corridorSize === null) {
            corridorSize = 10;
          } else {
            corridorSize = segment.corridorSize;
          }
          let pointsCorridor = computeCorridorSegment(
            latLngA,
            latLngB,
            corridorSize
          );
          let corridorPath = this.createCorridorPath(pointsCorridor);
          let corridorPolygon = new this.google.maps.Polygon({
            path: corridorPath,
            strokeColor: "#FF0000",
            fillOpacity: 0,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            map: this.map,
            visible: true,
          });

          corridorPolygons.push({
            segment: segment,
            corridorPolygon: corridorPolygon,
          });
        }
      } else {
        this.onUpdateCorridor(segment);
      }
    },
    onRemoveCorridor(segment) {
      let corridorObj = corridorPolygons.find(
        (cp) => cp.segment.id === segment.id
      );
      if (corridorObj) {
        corridorObj.corridorPolygon.setVisible(false);
        this.onChangeSegmentColor(segment, "fixed");
      }
    },
    onUpdateCorridor(segment) {
      let corridorObj = corridorPolygons.find(
        (cp) => cp.segment.id === segment.id
      );
      if (corridorObj) {
        let latLngA, latLngB, corridorSize;
        latLngA = this.searchLatLngPoint(corridorObj.segment.firstPointId);
        latLngB = this.searchLatLngPoint(corridorObj.segment.secondPointId);
        if (latLngA && latLngB) {
          if (segment.corridorSize === null) {
            corridorSize = 10;
          } else {
            corridorSize = segment.corridorSize;
          }
          let pointsCorridor = computeCorridorSegment(
            latLngA,
            latLngB,
            corridorSize
          );
          let corridorPath = this.createCorridorPath(pointsCorridor);

          corridorObj.corridorPolygon.setPath(corridorPath);
          corridorObj.corridorPolygon.setVisible(true);
        }
      }
    },
    onShowSearchedSegments(oldSegments, newSegments, points) {
      if (newSegments.length > 0) {
        if (newSegments[0].id != "new") {
          oldSegments.forEach((segment) => {
            this.removeSegment(segment);
          });
          this.resetPoints()
          const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
          if (equals(this.points, points)) {
            newSegments.forEach((segment) => {
              this.addSegment(segment);
            });
            if (oldSegments.length != newSegments.length) {
              newSegments.forEach((segment) => {
                const found = this.segmentPolylines.find((polylineObj) =>polylineObj.segment.id === segment.id);
                found.polyline.setOptions({
                  strokeColor: segmentColors.EDITING,
                });
              });
            }
            if (oldSegments.length == newSegments.length) {
              this.segmentPolylines.forEach((segmentObj) => {
                segmentObj.polyline.setOptions({
                  strokeColor: segmentColors.FIXED,
                });
              });
            }
          }
        }
      }
    },
    onDrawPanMarker(segment) {
      if (segment.firstPointId && segment.secondPointId) {
        let firstMarkerObj = this.markers.find((m) => m.point.id == segment.firstPointId);
        let secondMarkerObj = this.markers.find((m) => m.point.id == segment.secondPointId);

        if (firstMarkerObj && secondMarkerObj) {
          const firstPoint = new this.google.maps.LatLng(firstMarkerObj.point.latitude, firstMarkerObj.point.longitude);
          const secondPoint = new this.google.maps.LatLng(secondMarkerObj.point.latitude, secondMarkerObj.point.longitude);
          const lat = (firstPoint.lat() + secondPoint.lat()) / 2;
          const lng = (firstPoint.lng() + secondPoint.lng()) / 2;
          const defaultRotation = this.google.maps.geometry.spherical.computeHeading(firstPoint, secondPoint);
          let url;

          if (segment.panAngle !== null) {
            url = createPanMarkerUrl(segment.panAngle)
          } else {
            url = createPanMarkerUrl(defaultRotation)
          }

          let marker = new this.google.maps.Marker({
            position: new this.google.maps.LatLng(lat, lng),
            icon: {
              url,
              anchor: new this.google.maps.Point(25, 25),
              scaledSize: new this.google.maps.Size(50, 50)
            },
            map: this.map
          });

          let panMarkerObj = {
            segment: segment,
            marker,
            defaultRotation
          };
          this.segmentPanMarkers.push(panMarkerObj);
        }
      }
    },
    onRemovePanMarker(segment) {
      const segmentPanMarkerIndex = this.segmentPanMarkers.findIndex(sp => sp.segment.id === segment.id);
      if (segmentPanMarkerIndex !== -1) {
        this.segmentPanMarkers[segmentPanMarkerIndex].marker.setMap(null);
        this.segmentPanMarkers.splice(segmentPanMarkerIndex, 1)
      }
    },
    onUpdatePanMarker(segment) {
      const segmentPanMarkerIndex = this.segmentPanMarkers.findIndex(sp => sp.segment.id === segment.id);
      if (segmentPanMarkerIndex !== -1) {
        let url;

        if (segment.panAngle !== null) {
          url = createPanMarkerUrl(segment.panAngle)
        } else {
          url = createPanMarkerUrl(this.segmentPanMarkers[segmentPanMarkerIndex].defaultRotation)
        }

        this.segmentPanMarkers[segmentPanMarkerIndex].marker.setIcon({
          url,
          anchor: new this.google.maps.Point(25, 25),
          scaledSize: new this.google.maps.Size(50, 50)
        })
      }
    }
  },
  async mounted() {
    await this.mapInitPromise;
    this.resetMapConfig();
    this.resetPoints();
    this.resetSegments();
    this.map.setZoom(this.$store.getters.getZoom());
    if (this.$store.getters.getCenter().lat && this.$store.getters.getCenter().lng)
      this.map.setCenter(this.$store.getters.getCenter());
    clickListener = this.map.addListener("click", e => {
      this.eventBus.$emit("deselect-segment");
    });

    this.eventBus.$on("edit-segment", this.onEditingSegment);

    this.eventBus.$on("change-segment-color", this.onChangeSegmentColor);

    this.eventBus.$on("remove-corridor", this.onRemoveCorridor);
    this.eventBus.$on("update-corridor", this.onUpdateCorridor);
    this.eventBus.$on("draw-corridor", this.onDrawCorridor);
    this.eventBus.$on("remove-pan-marker", this.onRemovePanMarker);
    this.eventBus.$on("update-pan-marker", this.onUpdatePanMarker);
    this.eventBus.$on("draw-pan-marker", this.onDrawPanMarker);

    this.eventBus.$on("show-searched-segments", this.onShowSearchedSegments);
  },
  beforeDestroy() {
    if (this.map.getBounds().lc && this.map.getBounds().Eb) {
      var lat = numAverage([this.map.getBounds().lc.g, this.map.getBounds().lc.i]);
      var lng = numAverage([this.map.getBounds().Eb.g,this.map.getBounds().Eb.i])
      this.$store.commit('SET_CENTER', {
        lat: lat,
        lng: lng
      })
    }
    this.$store.commit("SET_ZOOM", this.map.getZoom())
    this.google.maps.event.removeListener(clickListener);
    this.removeAllSegments();
    this.removeAllPoints();
  },
  watch: {
    editingTarget: function(newTarget, oldTarget) {
      if (newTarget !== null && oldTarget === null) {
        if (isFPManager) {
          this.markers.forEach((markerObj) => this.addClickListener(markerObj));
        }

        segmentWatched = this.segmentPolylines.find(
          (seg) => seg.segment.id === newTarget.id
        );
        if (segmentWatched) {
          segmentWatched.polygon.setVisible(true);
          segmentWatched.polyline.setOptions({
            strokeColor: "#0050aa",
          });
          startPointIdClick = segmentWatched.segment.firstPointId;
          endPointIdClick = segmentWatched.segment.secondPointId;
          safePointIdClick = segmentWatched.segment.safePointId;
        }

        if (newTarget.id === "new") {
          this.addSegment(newTarget);
        } else {
          this.refreshSegmentPolyline(newTarget);
          this.showMarkersEdition(newTarget);
        }
      } else if (newTarget !== null && oldTarget !== null) {
        this.refreshSegmentPolyline(newTarget);
      } else {
        const newSegmentPolyline = this.segmentPolylines.find(
          (sp) => sp.segment.id === "new"
        );

        if (newSegmentPolyline) {
          this.removeSegment(newSegmentPolyline.segment);
        } else {
          const segment = this.segmentPolylines.find(
            (sp) => sp.segment.id === oldTarget.id
          );
          this.refreshSegmentPolyline(segment);
          segment.polyline.setOptions({
            strokeColor: "#F0DE00",
          });
        }

        startPointIdClick = null;
        endPointIdClick = null;
        safePointIdClick = null;

        if (segmentWatched) {
          segmentWatched.polygon.setVisible(false);
          segmentWatched.polyline.setOptions({
            strokeColor: "#F0DE00",
          });
        }
        segmentWatched = null;
        this.markers.forEach((markerObj) => {
          this.google.maps.event.clearListeners(markerObj.marker, "click");
        });
      }
    },
    segments: function(newSegments, oldSegments) {
      if (!this.google) {
        return
      }
      if (newSegments.length === 0) {
        this.removeAllSegments();
        this.resetPoints();
      } else {
        let toDelete = [];
        let toAdd = [];

        oldSegments.forEach((segment) => {
          const found = newSegments.find(
            (nSegment) => nSegment.id === segment.id
          );
          if (!found) {
            toDelete.push(segment);
          } else if (
            found.firstPointId !== segment.firstPointId ||
            found.secondPointId !== segment.secondPointId ||
            found.safePointId !== segment.safePointId) {
            toDelete.push(segment);
            toAdd.push(found);
          }
        });

        let segments = [];
        this.segmentPolylines.forEach((sp) => {
          segments.push(sp.segment);
        });

        newSegments.forEach((ns) => {
          let exist = segments.find((sp) => sp.id === ns.id);
          if (!exist) {
            toAdd.push(ns);
          }
        });

        toDelete.forEach((segment) => {
          this.removeSegment(segment);
        });
      toAdd.forEach((segment) => {
        this.resetPoints()
        this.addSegment(segment);
      });
      }
    },
    points: function(newPoints, oldPoints) {
      oldPoints.forEach((point) => {
        this.removePoint(point);
      });

      newPoints.forEach((point) => {
        this.addMarker(point);
      });
      this.resetMapConfig()
    },
    safePoints: function(newSafepoints, oldSafepoints) {
      oldSafepoints.forEach((point) => {
        this.removePoint(point);
      });

      newSafepoints.forEach((point) => {
        this.addMarker(point);
      });
    },
  }
};
</script>
